<template>
  <ul class="list-inline share-follow">
    <li class="newsletter d-none">
      <router-link
        class="btn"
        :to="`/${$t('lang')}/news`"
        rel="nofollow"
        :title="$t('subscribe')"
      >
        <i class="fas fa-envelope fa-lg fa-fw fa-shape-square"></i>
        <span
          class="sr-only"
          v-html="$t('subscribe')"
        ></span>
      </router-link>
    </li>
    <li
      v-if="mastodon !== ''"
      class="mastodon"
    >
      <a
        class="btn"
        :href="mastodon"
        rel="noopener noreferrer nofollow"
        target="_blank"
        :title="`${$t('shareOn')} Mastodon`"
      >
        <i class="fab fa-mastodon fa-lg fa-fw fa-shape-square"></i>
        <span class="sr-only">Mastodon</span>
      </a>
    </li>
    <li class="diaspora">
      <a
        class="btn"
        :href="diaspora.join('')"
        rel="noopener noreferrer nofollow"
        target="_blank"
        :title="`${$t('shareOn')} Diaspora`"
      >
        <i class="fab fa-diaspora fa-lg fa-fw fa-shape-square"></i>
        <span class="sr-only">Diaspora</span>
      </a>
    </li>
    <li class="twitter">
      <a
        class="btn"
        :href="twitter.join('')"
        rel="noopener noreferrer nofollow"
        target="_blank"
        :title="`${$t('shareOn')} Twitter`"
      >
        <i class="fab fa-twitter fa-lg fa-fw fa-shape-square"></i>
        <span class="sr-only">Twitter</span>
      </a>
    </li>
    <li class="facebook">
      <a
        class="btn"
        :href="facebook.join('')"
        rel="noopener noreferrer nofollow"
        target="_blank"
        :title="`${$t('shareOn')} Facebook`"
      >
        <i class="fab fa-facebook-f fa-lg fa-fw fa-shape-square"></i>
        <span class="sr-only">Facebook</span>
      </a>
    </li>
    <li class="reddit">
      <a
        class="btn"
        :href="reddit"
        rel="noopener noreferrer nofollow"
        target="_blank"
        :title="`${$t('followOn')} Reddit`"
      >
        <i class="fab fa-reddit fa-lg fa-fw fa-shape-square"></i>
        <span class="sr-only">Reddit</span>
      </a>
    </li>
  </ul>
</template>

<script>
export default {
  data() {
    const picture = `${this.$t('baseurl')}/img/${this.$route.meta.lang}/support-mobilizon.png`;
    const link = `${this.$t('baseurl')}/${this.$route.meta.lang}`;
    const text = this.$t('home.intro');
    const title = `${this.$t('meta.title', '-t')} - ${this.$t('home.title', '-t')}`;

    return {
      mastodon: '', // Todo add pouet link
      diaspora: [
        'https://share.diasporafoundation.org/',
        `?title=${encodeURIComponent(text)}`,
        `&amp;url=${link}`,
      ],
      twitter: [
        'https://twitter.com/intent/tweet',
        `?text=${encodeURIComponent(text)} ${link}`,
      ],
      facebook: [
        'https://www.facebook.com/sharer/sharer.php',
        `?u=${encodeURIComponent(link)}`,
        `&amp;picture=${encodeURIComponent(picture)}`,
        `&amp;title=${encodeURIComponent(title)}`,
        `&amp;description=${encodeURIComponent(text)}`,
      ],
      reddit: this.$t('link.reddit'),
    };
  },
};
</script>

<style lang="scss">
.share-follow {
  display: inline-flex;

  a {
    margin-right: 5px;
    padding: 0rem;
    border-radius: 0;
  }

  li {
    a {
      color: #fff;
      border-width: 0.2em !important;
      border-style: solid;
      border-color: #fff;

      &:hover, &:focus {
        opacity: 0.8;
        color: #fff;
      }

      i::before {
        color: #fff;
      }
    }
  }

  .newsletter a {
    background: transparent;
    border-color: var(--j-s2);

    i::before {
      color: var(--j-s5);
    }
  }
  .mastodon i {
    background: #3088D4;
  }
  .diaspora i {
    background: #464646;
  }
  .twitter i {
    background: #1da1f2;
  }
  .facebook i {
    background: #1877f2;
  }
  .reddit i {
    background: #FF4500;
  }
}
</style>
