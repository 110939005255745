<template>
  <main>
    <section>
      <b-container>
        <b-col
          id="page-header"
          lg="8"
        >
          <h2 v-html="$t('medias.title')"></h2>
        </b-col>
      </b-container>
    </section>

    <section>
      <b-container>
        <b-row>
          <b-col lg="8">
            <div class="article">
              <h3
                id="press"
                v-html="$t('medias.press.title')"
              ></h3>
              <div v-html="$t('medias.press.md')"></div>
            </div>
            <div class="article">
              <h3
                id="visual"
                v-html="$t('medias.visual.title')"
              ></h3>
              <b-carousel
                v-model="slide.square"
                :interval="0"
                controls
                indicators
                :label-prev="$t('carousel.prev')"
                :label-next="$t('carousel.next')"
                :label-goto-slide="$t('carousel.goto')"
                :label-indicators="$t('carousel.indicator')"
              >
                <b-carousel-slide
                  v-for="(filename) in squares"
                  :key="filename"
                  :img-src="`${$t('baseurl')}img/${$route.meta.lang}/${filename}-mobilizon.png`"
                />
              </b-carousel>

              <h3
                id="mockup"
                v-html="$t('medias.mockup.title')"
              ></h3>
              <b-carousel
                v-model="slide.mockup"
                :interval="0"
                controls
                indicators
                :label-prev="$t('carousel.prev')"
                :label-next="$t('carousel.next')"
                :label-goto-slide="$t('carousel.goto')"
                :label-indicators="$t('carousel.indicator')"
              >
                <b-carousel-slide
                  v-for="n in 3"
                  :key="n"
                  :img-src="`${$t('baseurl')}img/mockup/0${n}.jpg`"
                />
              </b-carousel>
            </div>
            <div class="article">
              <h3
                id="speakabout"
                v-html="$t('medias.speakabout.title')"
              ></h3>
              <p v-html="$t('medias.speakabout.intro')"></p>
              <table
                v-if="articles.length > 0"
                class="table"
              >
                <thead class="thead-dark">
                  <tr>
                    <th
                      scope="col"
                      v-html="$t('medias.speakabout.th1')"
                    ></th>
                    <th
                      scope="col"
                      v-html="$t('medias.speakabout.th2')"
                    ></th>
                    <th
                      scope="col"
                      v-html="$t('medias.speakabout.th3')"
                    ></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in articles"
                    :key="item[3]"
                  >
                    <td
                      v-html="(new Intl.DateTimeFormat($t('lang')).format(new Date(item[1])))"
                    ></td>
                    <td v-html="item[2]"></td>
                    <td>
                      <a
                        :href="item[3]"
                        :hreflang="item[0]"
                        v-html="$t(item[4])"
                      ></a>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div v-if="articles.length < 5 && allArticles.length > 0">
                <p v-html="$t('medias.speakabout.empty_lang')"></p>
                <table
                  v-if="allArticles.length > 0"
                  class="table"
                >
                  <thead class="thead-dark">
                    <tr>
                      <th
                        scope="col"
                        v-html="$t('medias.speakabout.th1')"
                      ></th>
                      <th
                        scope="col"
                        v-html="$t('medias.speakabout.th2')"
                      ></th>
                      <th
                        scope="col"
                        v-html="$t('medias.speakabout.th3')"
                      ></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item in allArticles"
                      :key="item[3]"
                    >
                      <td
                        v-html="(new Intl.DateTimeFormat($t('lang')).format(new Date(item[1])))"
                      ></td>
                      <td v-html="item[2]"></td>
                      <td>
                        <a
                          :href="item[3]"
                          :hreflang="item[0]"
                        >
                          <span v-html="$t(item[4])"></span>
                          <span v-if="item[0] !== $t('lang')">
                            [{{ item[0] }}]
                          </span>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="article">
              <h3
                id="about"
                v-html="$t('medias.about.title')"
              ></h3>
              <div v-html="$t('medias.about.list')"></div>
              <b-card
                class="overflow-hidden"
                no-body
              >
                <b-row no-gutters>
                  <b-col
                    class="bg-light d-flex align-items-center"
                    md="4"
                  >
                    <b-card-img
                      class="rounded-0"
                      :src="`${$t('baseurl')}img/biglogo-notxt.png`"
                    />
                  </b-col>
                  <b-col md="8">
                    <b-card-body title="Framasoft">
                      <b-card-text v-html="$t('medias.about.desc')" />
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </div>
          </b-col>
          <!-- Right column -->
          <b-col lg="4">
            <div class="sticky-top">
              <div class="bloc">
                <nav>
                  <ul>
                    <li
                      v-for="key in Object.keys($t('medias')).filter(i => !['title'].includes(i))"
                      :key="key"
                    >
                      <a
                        :href="`#${key}`"
                        v-html="$t(`medias.${key}.title`)"
                      ></a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </main>
</template>

<script>

export default {
  data() {
    return {
      slide: {
        banner: 0,
        square: 0,
        mockup: 0,
      },
      squares: [
        'action',
        'care',
        'change',
        'common',
        'concieved',
        'control',
        'events',
        'freed',
        'software-to-the-people',
      ],
    };
  },

  computed: {
    articles() {
      return this.$t('medias.speakabout.list')
        .filter(article => article[0] === this.$t('lang')
          || (article[0] === 'en' && this.$t('lang') !== 'fr'));
    },

    allArticles() {
      return this.$t('medias.speakabout.list')
        .filter(article => article[0] !== this.$t('lang'));
    },
  },
};
</script>

<style lang="scss">
#medias {
  .article {
    background: #fff;
    margin-bottom: 40px;
    padding: 30px;
  }

  h5, #mockup {
    margin-top: 20px;
  }

  blockquote {
    border-left: 0.2em solid #333;
    position: relative;
    display: block;
    padding: 10px 20px;
    margin: 15px;
    background: var(--j-p2-light10);
    color: #454542;
  }

  .carousel {
    background: var(--j-s5-light30);
    padding: 20px;
  }

  .carousel img {
    margin: 0px auto 35px;
  }

  .carousel:nth-of-type(2) img {
    max-width: 420px;
  }

  .carousel-control-prev,
  .carousel-control-next {
    background: transparent !important;

    &:hover,
    &:focus {
      background:  var(--j-s5-light25) !important;
    }
  }
}
</style>
