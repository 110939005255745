<template>
  <main>
    <section>
      <b-container>
        <b-col
          id="page-header"
          lg="8"
          offset-lg="2"
        >
          <h2 v-html="$t('hof.title')"></h2>
        </b-col>
        <b-row>
          <b-col
            lg="8"
            offset-lg="2"
          >
            <h3 v-html="$t('hof.sponsors')"></h3>
            <div class="text-center">
              <a href="https://www.sudeducation.org/">
                <div class="thumbnail">
                  <img
                    alt="Sud Éducation"
                    :src="`${$t('baseurl')}img/sponsors/sud-education.png`"
                  />
                </div>
              </a>
              <a href="https://agile-france.org/">
                <div class="thumbnail">
                  <img
                    alt="Agile France"
                    :src="`${$t('baseurl')}img/sponsors/agile-france.png`"
                  />
                </div>
              </a>
            </div>

            <h3 v-html="$t('hof.donators')"></h3>
            <div
              v-for="(reward, j) in rewards"
              :key="j"
              :class="`bloc rwd${j + 1}`"
            >
              <div v-if="donators[`rwd-${reward}`].length > 0">
                <h4
                  class="sr-only"
                  v-html="$t(`home.funding.rewards['d${reward}'].title`)"
                ></h4>
                <ul class="list-inline text-center">
                  <li
                    v-for="(donator, index) in donators[`rwd-${reward}`]"
                    :key="index"
                    class="list-inline-item"
                    v-text="donator.nickname"
                  ></li>
                </ul>
              </div>
            </div>

            <h3 v-html="$t('hof.dev')"></h3>
            <div class="bloc rwd1">
              <ul class="list-inline text-center">
                <li class="list-inline-item">
                  tcit
                </li>
                <li class="list-inline-item">
                  mcpaccard
                </li>
                <li class="list-inline-item">
                  GeoffreyDorne
                </li>
                <li class="list-inline-item">
                  Chocobozzz
                </li>
                <li class="list-inline-item">
                  l-vincent-l
                </li>
                <li class="list-inline-item">
                  setop
                </li>
                <li class="list-inline-item">
                  lahax
                </li>
              </ul>
              <p class="text-center">
                <b-button
                  :href="$t('git.mobilizon')"
                  variant="secondary"
                >
                  <i class="fab fa-gitlab fa-lg"></i>
                  <span v-html="$t('hof.contrib')"></span>
                </b-button>
              </p>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </main>
</template>

<script>
export default {
  data() {
    // Init count and donators
    const rewards = [1, 42, 88];
    const donators = {};
    for (let j = 0; j < rewards.length; j += 1) {
      donators[`rwd-${rewards[j]}`] = [];
    }

    return {
      start: '2019-05-14',
      end: '2019-07-10',
      steps: [20000, 35000, 50000],
      activeStep: 0,
      list: [],
      rewards,
      donators,
    };
  },
  created() {
    if (!window.vuefsPrerender) {
      fetch('https://joinmobilizon.org/2019-mbz.json')
        .then(res => res.json())
        .then((data) => {
          this.list = data.list;
          this.updateData();
        })
        .catch((err) => { console.error('Unable to load donations', err); }); // eslint-disable-line
    }
  },
  methods: {
    updateData() {
      // Fill donators
      for (let i = 0; i < this.list.length; i += 1) {
        if (
          !this.list[i].monthly
          && new Date(this.start) < new Date(this.list[i].date.replace(/ /g, 'T'))
        ) {
          for (let j = 0; j < this.rewards.length; j += 1) {
            if (
              this.list[i].don >= this.rewards[j]
              && this.list[i].nickname !== 'Anonymous'
            ) {
              if (j !== this.rewards.length - 1) {
                if (this.list[i].don < this.rewards[j + 1]) {
                  this.donators[`rwd-${this.rewards[j]}`].push(this.list[i]);
                }
              } else {
                this.donators[`rwd-${this.rewards[j]}`].push(this.list[i]);
              }
            }
          }
        }
      }
      this.rewards = this.rewards.reverse();
    },
  },
};
</script>

<style lang="scss">
#hall-of-fame {
  main {
    .bloc {
      margin-left: 0;

      li {
        margin-right: 10px;

        &:nth-of-type(n+2):before {
          content: "✷";
          margin-right: 10px;
          color: #999;
          font-size: small;
        }
      }
    }

    .bloc.rwd1 ul {
      font-size: larger;

      li:nth-of-type(n+2):before {
        color: var(--j-p2);
      }

      li:nth-of-type(2n+3):before {
        color: var(--j-r1);
      }

      li:nth-of-type(3n+4):before {
        color: var(--j-i1);
      }
    }

    .bloc.rwd2 ul {
      li:nth-of-type(2n+2):before {
        color: var(--j-p2);
      }
    }

    .bloc.rwd3 ul {
      font-size: smaller;
    }
  }
}
</style>
