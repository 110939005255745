import main from './data/main.yml';
import merge from '../commons/plugins/merge';

const config = process.env.CONFIG;
const scripts = document.getElementsByTagName('script');

let data = {}; // eslint-disable-line

for (let i = 0; i < config.commons.enabled.length; i += 1) {
  const req = require(`../commons/data/${config.commons.enabled[i]}.yml`) || {}; // eslint-disable-line
  data[config.commons.enabled[i]] = merge
    .$(data[config.commons.enabled[i]], JSON.parse(JSON.stringify(req)));
}

data = merge.$(data, JSON.parse(JSON.stringify(main)));

data.color.soft = '<b style="color:#054ed2">K</b><b style="color:#000">aihuri</b>';

Object.assign(data, {
  '/': `/${config.base.replace(/(.+)/, '$1/')}`,
  base: config.base,
  baseurl: config.url,
  config,
  date: config.date,
  hash: window.location.hash.replace('#', ''),
  host: window.location.host,
  inframe: window.top.location !== window.self.document.location ? 'true' : 'false',
  url: window.location.href,
  year: {
    current: (new Date().getFullYear()).toString(),
    next: (new Date().getFullYear() + 1).toString(),
  },
});

data.self = new URL(scripts[scripts.length - 1].src, data.url).href;

if (!/^http/.test(data.baseurl)) {
  data.baseurl = data.self.split('/').slice(0, -1).join('/').replace(/\/?$/, '/');
}

export default data;
