import { render, staticRenderFns } from "./Quote.vue?vue&type=template&id=6fa928aa&"
var script = {}
import style0 from "./Quote.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports